import { UIEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Space, Spin, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  commonApi,
  NotificationInterface,
  NotificationsTypes,
  useGetNotificationsQuery,
  useGetUnreadNotificationsQuery,
  useSetReadNotificationMutation,
} from "api";
import { dateTimeFormat, format, getRouteByName } from "libs";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setAllNotificationsPage,
  setUnreadNotificationsPage,
} from "store/appSettingsSlice";

interface NotificationsInterface {
  unread?: boolean;
  width?: number | string;
  height?: number | string;
}

export const Notifications = ({
  unread = false,
  height = 450,
  width = 350,
}: NotificationsInterface) => {
  const navigate = useNavigate();

  const { allPage, unreadPage } = useAppSelector(
    (state) => state.appSettings.notifications
  );
  const dispatch = useAppDispatch();

  const { data, isLoading, isFetching } = (
    unread ? useGetUnreadNotificationsQuery : useGetNotificationsQuery
  )({
    page: unread ? unreadPage : allPage,
  });

  const [setNotificationReadMutation] = useSetReadNotificationMutation();

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const { target }: { target: any } = e;

    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !isFetching &&
      (data?.pagination.currentPage || 1) < (data?.pagination.lastPage || 1)
    ) {
      dispatch(
        unread ? setUnreadNotificationsPage() : setAllNotificationsPage()
      );
    }
  };

  const getRightNavigationPath = (notification: NotificationInterface) => {
    switch (notification.type) {
      case NotificationsTypes.NEW_REPORT_CREATED:
        return {
          pathname: getRouteByName("ReportsPage"),
          search: `filters[id][$eq]=${notification.data.reportId}`,
        };
      case NotificationsTypes.NEW_POST_CREATED:
        return {
          pathname: getRouteByName("ListingPage", {
            listingId: notification.data.post,
          }),
        };
      case NotificationsTypes.NEW_USER_REGISTERED:
        return {
          pathname: getRouteByName("UserPage", {
            userId: notification.data.userId,
          }),
        };
      case NotificationsTypes.UPCOMING_POST:
        return { pathname: getRouteByName(""), search: "" };
      case NotificationsTypes.USER_UPGRADE:
        return {
          pathname: getRouteByName("UserPage", {
            userId: notification.data.userId,
          }),
        };
      default:
        return { pathname: "#" };
    }
  };

  const onNotificationClick = useCallback(
    (notification: NotificationInterface) => {
      setNotificationReadMutation({ id: notification.id });

      dispatch(
        commonApi.util.updateQueryData(
          "getUnreadNotifications",
          { page: unreadPage },
          (data) => {
            data.data = data.data.filter((i) => i.id !== notification.id);
          }
        )
      );
      dispatch(
        commonApi.util.updateQueryData(
          "getNotifications",
          { page: allPage },
          (data) => {
            data.data = data.data.map((i) =>
              i.id === notification.id
                ? { ...i, readAt: dayjs().toString() }
                : i
            );
          }
        )
      );

      navigate(getRightNavigationPath(notification));
    },
    []
  );

  return (
    <Spin spinning={isLoading || isFetching}>
      <div
        style={{ minHeight: 200, overflowY: "auto", height, width }}
        onScroll={onScroll}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {data?.data.map((i) => (
            <div
              key={i.id}
              style={{
                cursor: "pointer",
                borderRadius: 8,
                padding: 8,
              }}
              className={`${i.readAt ? "" : "not-readed"} clickable`}
              onClick={() => onNotificationClick(i)}
            >
              <Space>
                <Avatar src="" icon={<UserOutlined />} />
                <div>
                  <div>
                    <Typography.Text strong>{i.title}</Typography.Text>
                  </div>
                  <div>{format(i.createdAt, dateTimeFormat)}</div>
                </div>
              </Space>
            </div>
          ))}
        </Space>
      </div>
    </Spin>
  );
};
