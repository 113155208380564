import { createApi } from "@reduxjs/toolkit/query/react";
import { Comment, defaultApiConfig, WithPagination } from "./index";
import { ListingInterface } from "./listings.types";
import { $Object } from "types/object";
import { defaultQueryStringify } from "libs";

export const listingsApi = createApi({
  ...defaultApiConfig,
  reducerPath: "listingsApi",
  tagTypes: ["Listings", "Listing"],
  endpoints: (builder) => ({
    getListings: builder.query<WithPagination<ListingInterface>, $Object>({
      query: (params) => `admin/posts?${defaultQueryStringify(params)}`,
      providesTags: ["Listings"],
    }),
    getListing: builder.query<ListingInterface, { id?: number }>({
      query: ({ id }) => `admin/post/${id}`,
      providesTags: (result) => [{ type: "Listing", id: result?.id }],
    }),
    updateListings: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `admin/post/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_, __, arg) => [
        "Listings",
        { type: "Listing", id: arg.id },
      ],
    }),
    getListingComments: builder.query<Comment[], { id?: number }>({
      query: ({ id, ...params }) =>
        `admin/comments/post/${id}?${defaultQueryStringify(params)}`,
    }),
    updateListingStatus: builder.mutation<
      Comment[],
      { id?: number; reason?: string; status: number }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/update-post-status/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_, __, arg) => [
        "Listings",
        { type: "Listing", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetListingsQuery,
  useGetListingQuery,
  useGetListingCommentsQuery,
  useUpdateListingsMutation,
  useUpdateListingStatusMutation,
} = listingsApi;
