import { $Object } from "../types/object";

export const withValues = (obj: $Object): $Object =>
  Object.keys(obj)
    .filter((i) => {
      const value = obj[i];

      return value !== null && value !== undefined && value !== "";
    })
    .reduce((acc, current) => ({ ...acc, [current]: obj[current] }), {});

export function getOnlyExistingKeys<T extends $Object>(
  obj1: $Object,
  obj2: $Object
): T {
  const obj1Keys = Object.keys(obj1);

  return Object.keys(obj2).reduce(
    (acc, i) => ({ ...acc, ...(obj1Keys.includes(i) ? { [i]: obj2[i] } : {}) }),
    {} as T
  );
}

// rewrites types of default Object.keys function
export function keys<O extends object>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}
