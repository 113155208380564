import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultApiConfig, WithPagination } from "./index";
import { Message, RoomInterface } from "./chat.types";

export const chatApi = createApi({
  ...defaultApiConfig,
  reducerPath: "chatApi",
  endpoints: (builder) => ({
    getUserRooms: builder.query<
      WithPagination<RoomInterface>,
      { id: number; page: number }
    >({
      query: ({ id, page }) => `admin/rooms/user/${id}?page=${page}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData) => {
        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getRoomMessages: builder.query<
      WithPagination<Message>,
      { page: number; id?: number }
    >({
      query: ({ id, page }) => ({
        url: `admin/room-messages-by-id/${id}?page=${page}`,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs: { id } }) => {
        return id || endpointName;
      },
      merge: (currentCacheData, responseData) => {
        currentCacheData.pagination = responseData.pagination;
        currentCacheData.data.push(...responseData.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useGetUserRoomsQuery, useGetRoomMessagesQuery } = chatApi;
