import { useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DatePicker,
  Flex,
  Input,
  Layout,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { format, getRouteByName, palette } from "libs";
import { DownloadOutlined } from "@ant-design/icons";
import { useCustomToken, useFilters, useHash } from "hooks";
import { useGetTransactionsQuery } from "api/transactions";
import { TransactionStatuses } from "api";
import { enumKeys } from "types/object";
import { useAppSelector } from "store/hooks";

interface TransactionsPageInterface {
  filterKey?: string;
}

interface TransactionsFiltersInterface {
  "filters[post][user][id][$eq]"?: number[];
  "filters[currency][$eq]"?: number;
  "filters[status][$eq]"?: number;
  "filters[date][$gt]"?: string;
  "filters[date][$lte]"?: string;
  page: number;
}

export const TransactionsPage = ({
  filterKey = "transactions",
}: TransactionsPageInterface) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation()

  const { token } = useCustomToken();

  const { filters, onFiltersChange } = useFilters<TransactionsFiltersInterface>(
    {
      "filters[post][user][id][$eq]": undefined,
      "filters[currency][$eq]": undefined,
      "filters[status][$eq]": undefined,
      "filters[date][$gt]": undefined,
      "filters[date][$lte]": undefined,
      page: 1,
    },
    { keyword: filterKey }
  );

  const { isTransactionsRead, isUsersRead, isPostsRead, isAll } =
    useAppSelector((state) => state.loggedAdmin.parsedPermissions);

  const { data, isLoading, isFetching } = useGetTransactionsQuery(filters);

  useEffect(() => {
    if (!isTransactionsRead && !isAll) {
      navigate(getRouteByName("NotFoundPage"));
    }
  }, [isTransactionsRead, isAll]);

  useHash("transactions");

  useEffect(() => {
    if (userId) {
      onFiltersChange({ "filters[post][user][id][$eq]": [Number(userId)] });
    }
  }, [userId]);

  const usersColumns = useMemo<ColumnsType<any>>(
    () => [
      {
        title: "Anunt",
        dataIndex: "post",
        render: (post) =>
          isAll || isPostsRead ? (
            <Link to={getRouteByName("ListingPage", { listingId: post.id })}>
              {post.title}
            </Link>
          ) : (
            post.title
          ),
      },
      {
        title: "Utilizator",
        dataIndex: ["post", "user"],
        render: (user) =>
          isAll || isUsersRead ? (
            <Link to={getRouteByName("UserPage", { userId: user.id })}>
              {user.firstName} {user.lastName}
            </Link>
          ) : (
            <>
              {user.firstName} {user.lastName}
            </>
          ),
      },
      {
        title: "Cantitate",
        dataIndex: "quantity",
      },
      {
        title: "Pret unitate",
        dataIndex: "unitPrice",
      },
      {
        title: "VAT(%)",
        dataIndex: "vat",
      },
      {
        title: "VAT($)",
        dataIndex: "vat_value",
      },
      {
        title: "Total",
        dataIndex: "totalPrice",
      },
      {
        title: "Total(VAT)",
        dataIndex: "totalPriceWithVat",
      },
      {
        title: "Currency",
        dataIndex: "currency",
      },
      {
        title: "Statut",
        dataIndex: "status",
        render: (status) => t(TransactionStatuses[status]),
      },
      {
        title: "Data",
        dataIndex: "date",
        width: 200,
        render: (date) => (
          <Space>
            <Typography.Text>{format(date)}</Typography.Text>
            <Typography.Text>{format(date, "HH:mm A")}</Typography.Text>
          </Space>
        ),
      },
      {
        align: "end",
        fixed: "right",
        width: 50,
        render: () => (
          <DownloadOutlined style={{ fontSize: "1rem", cursor: "pointer" }} />
        ),
      },
    ],
    [token, isAll, isPostsRead, isUsersRead]
  );

  return (
    <>
      <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
        <Space wrap>
          <Input.Search placeholder="Search" />

          <Select
            style={{ width: 150 }}
            placeholder="Alege moneda"
            allowClear
            value={filters["filters[currency][$eq]"]}
            onChange={(val) =>
              onFiltersChange({ "filters[currency][$eq]": val })
            }
          >
            <Select.Option key="RON" value="RON">
              RON
            </Select.Option>
          </Select>

          <Select
            style={{ width: 150 }}
            placeholder="Alege statut"
            allowClear
            value={filters["filters[status][$eq]"]}
            onChange={(val) => onFiltersChange({ "filters[status][$eq]": val })}
          >
            {enumKeys(TransactionStatuses).map((i) => (
              <Select.Option key={i} value={TransactionStatuses[i]}>
                {t(i)}
              </Select.Option>
            ))}
          </Select>

          <DatePicker.RangePicker
            defaultValue={
              filters["filters[date][$gt]"] !== undefined
                ? [
                    dayjs(filters["filters[date][$gt]"], "YYYY-MM-DD"),
                    dayjs(filters["filters[date][$lte]"], "YYYY-MM-DD"),
                  ]
                : undefined
            }
            placeholder={["Creat de la", "Creat pina la"]}
            onChange={(date: any) => {
              onFiltersChange({
                "filters[date][$gt]":
                  date && date[0]
                    ? date[0].format("YYYY-MM-DDT00:00Z")
                    : undefined,
                "filters[date][$lte]":
                  date && date[1]
                    ? date[1].format("YYYY-MM-DDT23:59Z")
                    : undefined,
              });
            }}
          />
        </Space>
      </Flex>
      <Layout style={{ backgroundColor: palette.white }}>
        <Table
          columns={usersColumns}
          loading={isLoading || isFetching}
          dataSource={data?.data}
          rowKey="id"
          pagination={{
            total: data?.pagination.total,
            current: data?.pagination.currentPage,
            pageSize: data?.pagination.itemsPerPage,
            onChange: (page) => onFiltersChange({ page }),
            showSizeChanger: false,
          }}
          scroll={{ x: 1500 }}
        />
      </Layout>
    </>
  );
};
