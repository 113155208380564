export const localesRo = {
  PHYSICAL: "Fizica",
  JURIDICAL: "Juridica",
  PENDING: "In asteptare",
  ACTIVE: "Activ",
  INACTIVE: "Inactiv",
  BLOCKED: "Blocat",
  BLOCK: "Blocat",
  FREE: "Gratuit",
  PAID: "Platit",
  RESOLVED: "Rezolvat"
};
