import { useEffect, useState } from "react";
import {
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserStatusMutation,
} from "api/users";
import { useParams } from "react-router-dom";
import {
  App,
  Avatar,
  Button,
  Card,
  Flex,
  Form,
  GetProp,
  Grid,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { useTranslation } from "react-i18next";
import { Tag, UploadGallery } from "components";
import {
  useGetCategoriesQuery,
  useGetCountiesQuery,
  useGetCountriesQuery,
  useGetServicesQuery,
  UserStatuses,
  UserTypes,
} from "api";
import { SmartSelect } from "components/SmartSelect";
import { enumKeys } from "types/object";
import { formErrorHandler, getBase64, normFile } from "libs";
import { useAppSelector } from "store/hooks";
import { SetPasswordModal } from "./SetPasswordModal";

export type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const UserInfo = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const { notification } = App.useApp();
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [showResetPassModal, setShowResetPassModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAdditionalAgreeModal, setShowAdditionalAgreeModal] =
    useState<UserStatuses>();
  const [reason, setReason] = useState<string>();
  const [userPhoto, setUserPhoto] = useState<string>();
  const [companyLogo, setCompanyLogo] = useState<string>();
  const [companyPhotos, setCompanyPhotos] = useState<string[]>([]);

  const { isAll, isUsersEdit, isUsersDelete } = useAppSelector(
    (state) => state.loggedAdmin.parsedPermissions
  );

  const { data, isFetching, refetch } = useGetUserQuery({
    id: Number(params.userId),
  });

  const { data: categoriesData, isLoading: isCategoriesLoading } =
    useGetCategoriesQuery({});

  const { data: servicesData, isLoading: isServicesLoading } =
    useGetServicesQuery({});

  const [
    updateUserMutation,
    { isLoading, isSuccess: isSuccessUserUpdate, error: userUpdateError },
  ] = useUpdateUserMutation();

  const [
    updateUserStatusMutation,
    {
      isLoading: isUpdateUserStatusLoading,
      isSuccess: isUserUpdateStatusSuccess,
      error: updateStatusError,
    },
  ] = useUpdateUserStatusMutation();

  const [
    deleteUserMutation,
    { isLoading: isDeleteUserLoading, isSuccess, isError },
  ] = useDeleteUserMutation();

  const { data: dataCounties, isLoading: isCountiesLoading } =
    useGetCountiesQuery({});

  const breakpoints = Grid.useBreakpoint();

  const userTypeValue = Form.useWatch("type", form);
  const selectedCategories = Form.useWatch("categories", form);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      categories: data?.categories.map((i) => i.id),
      location: {
        country: data?.location.country.id,
        county: data?.location.county.id,
      },
      profilePicture: [data?.profilePicture],
      company: {
        ...data?.company,
        logo: [data?.company.logo],
      },
    });
    setUserPhoto(data?.profilePicture);
    setCompanyLogo(data?.company.logo);
  }, [data, form]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: "Utilizatorul a fost sters cu success!",
      });
      setShowDeleteModal(false);
      setReason("");
    }
  }, [isSuccess, notification]);

  useEffect(() => {
    if (isSuccessUserUpdate) {
      setShowResetPassModal(false);
      setIsEditMode(false);
      refetch();
      notification.success({
        message: "Utilizatorul a fost modificat cu success!",
      });
    }
  }, [isSuccessUserUpdate, notification, refetch]);

  useEffect(() => {
    if (userUpdateError) {
      form.setFields(formErrorHandler((userUpdateError as any).data));
    }
  }, [userUpdateError, form]);

  useEffect(() => {
    if (isError) {
      notification.error({
        message: "Utilizatorul nu poate fi sters!",
      });
      setShowDeleteModal(false);
      setReason("");
    }
  }, [isError, notification]);

  useEffect(() => {
    if (isUserUpdateStatusSuccess) {
      notification.success({
        message: "Utilizatorul a fost modificat cu success!",
      });
      setShowAdditionalAgreeModal(undefined);
    }
  }, [isUserUpdateStatusSuccess, notification]);

  useEffect(() => {
    if (updateStatusError) {
      notification.success({
        message: "Utilizatorul nu poate fi modificat!",
      });
      setShowAdditionalAgreeModal(undefined);
    }
  }, [updateStatusError, notification]);

  const getBlocksWidth = () => {
    if (!breakpoints.md) {
      return "100%";
    }

    if (!breakpoints.lg) {
      return "49%";
    }

    return "100%";
  };

  const validateAndSubmit = () =>
    form
      .validateFields()
      .then(() => onFormSubmit())
      .catch(() => null);

  const onFormSubmit = async () => {
    const formValues = form.getFieldsValue();
    const foundLocation = dataCounties?.find(
      (i) => i.id === formValues.location.county
    );

    updateUserMutation({
      id: data?.id,
      ...form.getFieldsValue(),
      profilePicture: userPhoto,
      company: {
        ...form.getFieldsValue().company,
        logo: companyLogo,
        mediaFiles: [
          ...(data?.company.mediaFiles.map((i) => i.id) || []),
          ...companyPhotos,
        ],
      },
      location: {
        ...formValues.location,
        latitude: foundLocation?.latitude || 0,
        longitude: foundLocation?.longitude || 0,
      },
    });
  };

  console.log(selectedCategories, "selectedCategories");

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ justifyContent: "flex-end", width: "100%" }}>
          {(isAll || isUsersEdit) && (
            <>
              {isEditMode && (
                <>
                  <Button onClick={() => setIsEditMode(false)}>Anuleaza</Button>
                  <Button
                    type="primary"
                    loading={isLoading}
                    onClick={validateAndSubmit}
                  >
                    Salveaza
                  </Button>
                </>
              )}

              {!isEditMode && (
                <Button type="primary" onClick={() => setIsEditMode(true)}>
                  Editeaza
                </Button>
              )}

              {data?.status === UserStatuses.PENDING && (
                <>
                  <Button
                    type="primary"
                    onClick={() =>
                      setShowAdditionalAgreeModal(UserStatuses.ACTIVE)
                    }
                  >
                    Aproba
                  </Button>
                  <Button
                    type="primary"
                    onClick={() =>
                      setShowAdditionalAgreeModal(UserStatuses.BLOCKED)
                    }
                  >
                    Respinge
                  </Button>
                </>
              )}

              <Button
                type="primary"
                onClick={() => setShowResetPassModal(true)}
              >
                Reseteaza parola
              </Button>
            </>
          )}

          {(isAll || isUsersDelete) && (
            <Button
              type="primary"
              danger
              onClick={() => setShowDeleteModal(true)}
            >
              Sterge
            </Button>
          )}
        </Space>

        <Form
          form={form}
          requiredMark={false}
          className="half-space-form"
          layout="vertical"
        >
          <Spin spinning={isFetching}>
            <Space
              align="start"
              style={{ width: "100%" }}
              styles={{
                item: {
                  width: getBlocksWidth(),
                },
              }}
              wrap={!breakpoints.lg}
            >
              <Card title="Utilizator">
                <Form.Item
                  name="profilePicture"
                  label={
                    <Typography.Text strong>Poza de profil:</Typography.Text>
                  }
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  {isEditMode ? (
                    <>
                      <Upload
                        customRequest={(data) =>
                          getBase64(data.file as FileType, (url) => {
                            setUserPhoto(url);
                          })
                        }
                        showUploadList={false}
                      >
                        <Avatar src={userPhoto} size={100} />
                      </Upload>
                    </>
                  ) : (
                    <Avatar src={data?.profilePicture} size={100} />
                  )}
                </Form.Item>

                <Form.Item
                  name="firstName"
                  label={<Typography.Text strong>Prenume:</Typography.Text>}
                  required
                  rules={[{ required: true, message: <></> }]}
                >
                  {isEditMode ? <Input /> : data?.firstName}
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label={<Typography.Text strong>Nume:</Typography.Text>}
                  required
                  rules={[{ required: true, message: <></> }]}
                >
                  {isEditMode ? <Input /> : data?.lastName}
                </Form.Item>
                <Form.Item
                  name="email"
                  label={<Typography.Text strong>Email</Typography.Text>}
                  required
                >
                  {isEditMode ? <Input /> : data?.email}
                </Form.Item>
                <Form.Item
                  name="type"
                  label={<Typography.Text strong>Tip:</Typography.Text>}
                  required
                  rules={[
                    { required: true, message: "Acest cimp este obligator" },
                  ]}
                >
                  {isEditMode ? (
                    <Select
                      style={{ width: 150 }}
                      placeholder="Alege tip"
                      allowClear
                    >
                      {enumKeys(UserTypes).map((i) => (
                        <Select.Option key={i} value={UserTypes[i]}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : data?.type ? (
                    t(UserTypes[data?.type])
                  ) : (
                    ""
                  )}
                </Form.Item>
                <Form.Item
                  name="status"
                  label={<Typography.Text strong>Statut:</Typography.Text>}
                  required
                >
                  {isEditMode ? (
                    <Select
                      style={{ width: 150 }}
                      placeholder="Alege statut"
                      allowClear
                    >
                      {enumKeys(UserStatuses).map((i) => (
                        <Select.Option key={i} value={UserStatuses[i]}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : data?.status ? (
                    t(UserStatuses[data?.status])
                  ) : (
                    ""
                  )}
                </Form.Item>

                <Form.Item
                  name="categories"
                  label={<Typography.Text strong>Categorii:</Typography.Text>}
                >
                  {isEditMode ? (
                    <Select mode="multiple" loading={isCategoriesLoading}>
                      {categoriesData?.map((i) => (
                        <Select.Option value={i.id} key={i.id}>
                          {i.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Space wrap>
                      {data?.categories.map((i) => (
                        <Tag key={i.name}>{i.name}</Tag>
                      ))}
                    </Space>
                  )}
                </Form.Item>

                <Form.Item
                  name="services"
                  label={<Typography.Text strong>Servicii:</Typography.Text>}
                >
                  {isEditMode ? (
                    <Select mode="multiple" loading={isServicesLoading}>
                      {servicesData
                        ?.filter((i) =>
                          selectedCategories.includes(i.category.id)
                        )
                        .map((i) => (
                          <Select.Option value={i.id} key={i.id}>
                            {i.name}
                          </Select.Option>
                        ))}
                    </Select>
                  ) : (
                    <Space wrap>
                      {data?.services.map((i) => (
                        <Tag key={i.name}>{i.name}</Tag>
                      ))}
                    </Space>
                  )}
                </Form.Item>

                <Form.Item
                  label={<Typography.Text strong>Verificat:</Typography.Text>}
                >
                  {data?.isVerified ? "Da" : "Nu"}
                </Form.Item>

                <Form.Item
                  label={
                    <Typography.Text strong>Ultima activitate:</Typography.Text>
                  }
                >
                  {data?.lastActivity}
                </Form.Item>
              </Card>

              <Space
                direction="vertical"
                styles={{ item: { width: "100%" } }}
                style={{ width: "100%" }}
              >
                {(data?.company.id ||
                  userTypeValue === UserTypes.JURIDICAL) && (
                  <Card title="Companie">
                    <Form.Item
                      name={["company", "logo"]}
                      label={
                        <Typography.Text strong>
                          Poza principala:
                        </Typography.Text>
                      }
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      {isEditMode ? (
                        <Upload
                          customRequest={(data) =>
                            getBase64(data.file as FileType, (url) => {
                              setCompanyLogo(url);
                            })
                          }
                          showUploadList={false}
                        >
                          <Avatar src={companyLogo} size={100} />
                        </Upload>
                      ) : (
                        <Avatar src={companyLogo} size={100} />
                      )}
                    </Form.Item>

                    <Form.Item
                      name={["company", "name"]}
                      label={
                        <Typography.Text strong>Denumire:</Typography.Text>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Acest cimp este obligator",
                        },
                      ]}
                    >
                      {isEditMode ? <Input /> : data?.company.name}
                    </Form.Item>

                    <Form.Item
                      name={["company", "mediaFiles"]}
                      label={<Typography.Text strong>Poze:</Typography.Text>}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      {isEditMode ? (
                        <UploadGallery
                          customRequest={() => null}
                          onImagesChange={setCompanyPhotos}
                        />
                      ) : (
                        <Image.PreviewGroup>
                          <Space>
                            {data?.company.mediaFiles.map((i) => (
                              <Image
                                key={i.url}
                                src={i.url}
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 8,
                                }}
                                wrapperStyle={{ width: 100, height: 100 }}
                              />
                            ))}
                          </Space>
                        </Image.PreviewGroup>
                      )}
                    </Form.Item>

                    <Form.Item
                      name={["company", "description"]}
                      label={
                        <Typography.Text strong>Descriere:</Typography.Text>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Acest cimp este obligator",
                        },
                      ]}
                      required
                    >
                      {isEditMode ? <Input.TextArea /> : "descriere"}
                    </Form.Item>
                  </Card>
                )}

                <Card title="Locatie">
                  <Form.Item
                    name={["location", "country"]}
                    label={<Typography.Text strong>Tara:</Typography.Text>}
                    required
                    rules={[
                      { required: true, message: "Acest cimp este obligator" },
                    ]}
                  >
                    {isEditMode ? (
                      <SmartSelect
                        query={useGetCountriesQuery}
                        placeholder="Alege tara"
                        allowClear
                      />
                    ) : (
                      data?.location.country.name
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["location", "county"]}
                    label={<Typography.Text strong>Judet:</Typography.Text>}
                    required
                    rules={[
                      { required: true, message: "Acest cimp este obligator" },
                    ]}
                  >
                    {isEditMode ? (
                      <Select
                        loading={isCountiesLoading}
                        className="capitalize"
                        placeholder="Alege judetul"
                        allowClear
                      >
                        {dataCounties?.map((i) => (
                          <Select.Option
                            key={i.id}
                            value={i.id}
                            className="capitalize"
                          >
                            {i.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      data?.location.county.name
                    )}
                  </Form.Item>
                </Card>
              </Space>

              <Flex gap="8px" vertical>
                <Card title="Legal Agreement">
                  <Form.Item
                    name={["legalAgreements", "termsAndConditions"]}
                    label={
                      <Typography.Text strong>
                        Termenii si conditiile:
                      </Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch />
                    ) : data?.legalAgreements.termsAndConditions ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["legalAgreements", "privacyPolicy"]}
                    label={
                      <Typography.Text strong>
                        Politica de confidentialitate:
                      </Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch />
                    ) : data?.legalAgreements.privacyPolicy ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                </Card>

                <Card title="Notificari">
                  <Form.Item
                    name={["notifications", "notifiedOnNewComments"]}
                    label={
                      <Typography.Text strong>
                        La comentarii noi:
                      </Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch />
                    ) : data?.notifications.notifiedOnNewComments ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["notifications", "notifiedOnNewPosts"]}
                    label={
                      <Typography.Text strong>La post-uri noi:</Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch />
                    ) : data?.notifications.notifiedOnNewPosts ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                  <Form.Item
                    name={["notifications", "notifiedOnNewMessages"]}
                    label={
                      <Typography.Text strong>La mesaje noi:</Typography.Text>
                    }
                  >
                    {isEditMode ? (
                      <Switch />
                    ) : data?.notifications.notifiedOnNewMessages ? (
                      "Da"
                    ) : (
                      "Nu"
                    )}
                  </Form.Item>
                </Card>
              </Flex>
            </Space>
          </Spin>
        </Form>
      </Space>

      {showResetPassModal && (
        <SetPasswordModal
          onCancel={() => setShowResetPassModal(false)}
          mutation={updateUserMutation}
          isLoading={isLoading}
        />
      )}

      {showAdditionalAgreeModal && (
        <Modal
          open
          title="Modificarea utilizatorul"
          okText="Modifica"
          cancelText="Inchide"
          styles={{ body: { margin: "2rem 0" } }}
          onCancel={() => setShowAdditionalAgreeModal(undefined)}
          onOk={() =>
            updateUserStatusMutation({
              id: data?.id,
              status: showAdditionalAgreeModal,
              reason,
            })
          }
          okButtonProps={{
            loading: isUpdateUserStatusLoading,
            disabled:
              UserStatuses.BLOCKED === showAdditionalAgreeModal && !reason,
          }}
        >
          <Typography.Text>
            Esti sigur ca vrei sa schimbi statul utilizatorului in "
            {UserStatuses[showAdditionalAgreeModal]}"?
          </Typography.Text>
          {UserStatuses.BLOCKED === showAdditionalAgreeModal && (
            <Form.Item label="Cauza:" style={{ marginTop: "1rem" }}>
              <Input
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Item>
          )}
        </Modal>
      )}

      {showDeleteModal && (
        <Modal
          open
          title="Modificarea postarea"
          okText="Modifica"
          cancelText="Inchide"
          styles={{ body: { margin: "2rem 0" } }}
          onCancel={() => setShowDeleteModal(false)}
          onOk={() =>
            deleteUserMutation({
              id: data?.id,
              reason,
            })
          }
          okButtonProps={{
            loading: isDeleteUserLoading,
            disabled: !reason,
          }}
        >
          <Typography.Text>
            Esti sigur ca vrei sa stergi acest utilizator?
          </Typography.Text>

          <Form.Item label="Cauza:" style={{ marginTop: "1rem" }}>
            <Input value={reason} onChange={(e) => setReason(e.target.value)} />
          </Form.Item>
        </Modal>
      )}
    </>
  );
};
