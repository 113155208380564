import dayjs from "dayjs";

export const today = dayjs();
export const dateFormat = "DD MMM YYYY";
export const dateFormatInput = "dd.MM.yyyy";
export const dateTimeFormat = "DD.MM.YYYY HH:mm";
export const dateTimeFormatInput = "dd.MM.yyyy HH:mm";
export const dateFormatDMCMY = "D MMM, YYYY";
export const dateTimeRowFormat = "DD MMM YYYY, HH:mm A";
export const dateSpecialFormat = "D MMMM YYYY";
export const timeSpecialFormat = "HH:mm A";
export const monthFormat = "MMM";
export const chatDateTime = "HH:mm | DD.MM.YYYY"

export const format = (date?: string, format?: string): string =>
  dayjs(date).format(format || dateFormat);

export const getDiffFromToday = (date: string) => {
  const now = dayjs();
  const timeToDecrease = dayjs(date);

  const year = now.diff(timeToDecrease, "year");
  if (year > 0) {
    return `${year.toString()} ${year === 1 ? "aboutAYear" : "years"}`;
  }

  const month = now.diff(timeToDecrease, "month");
  if (month > 0) {
    return `${month.toString()} ${month === 1 ? "aboutAMonth" : "months"}`;
  }

  const day = now.diff(timeToDecrease, "day");
  if (day > 0) {
    return `${day.toString()} ${day === 1 ? "aDay" : "days"}`;
  }

  const hour = now.diff(timeToDecrease, "hour");
  if (hour > 0) {
    return `${hour.toString()} ${hour === 1 ? "aboutAnHour" : "hours"}`;
  }

  const minute = now.diff(timeToDecrease, "minute");
  if (minute > 0) {
    return `${minute.toString()} ${minute === 1 ? "aboutAMinute" : "minutes"}`;
  }

  const second = now.diff(timeToDecrease, "second");
  if (second >= 0) {
    return `${second.toString()} ${
      second <= 1 ? "aboutASecond" : "seconds_ago"
    }`;
  }
};