import { PropsWithChildren, useEffect } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import {
  commonApi,
  NotificationsTypes,
  useRegisterFCMTokenMutation,
} from "api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { $Object } from "../types/object";
import dayjs from "dayjs";

const generateMessage = ({ data, notification }: $Object) => ({
  id: data.notificationId,
  data: {
    ...data,
    created_at: "2024-04-22 16:37:29",
  },
  type: NotificationsTypes.NEW_REPORT_CREATED,
  title: notification.title,
  readAt: "",
  createdAt: dayjs().toString(),
});

export const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { unreadPage, allPage } = useAppSelector(
    (state) => state.appSettings.notifications
  );

  const [mutationRegisterFCMToken] = useRegisterFCMTokenMutation();

  onMessage(messaging, (payload: any) => {
    console.log(payload, "payload")
    const generatedMessage = generateMessage(payload);

    dispatch(
      commonApi.util.updateQueryData(
        "getUnreadNotifications",
        { page: unreadPage },
        (data) => {
          data.data.unshift(generatedMessage);
          data.pagination.total += 1;
        }
      )
    );
    dispatch(
      commonApi.util.updateQueryData(
        "getNotifications",
        { page: allPage },
        (data) => {
          data.data.unshift(generatedMessage);
          data.pagination.total += 1;
        }
      )
    );
  });

  const channel = new BroadcastChannel("sw-messages");

  channel.onmessage = (event) => {
    console.log(event, "event")
    const generatedMessage = generateMessage(event.data);

    dispatch(
      commonApi.util.updateQueryData(
        "getUnreadNotifications",
        { page: unreadPage },
        (data) => {
          const isAlreadyAdded = data.data.find(
            (i) => i.id === generatedMessage.id
          );

          if (!isAlreadyAdded) {
            data.data.unshift(generatedMessage);
            data.pagination.total += 1;
          }
        }
      )
    );
    dispatch(
      commonApi.util.updateQueryData(
        "getNotifications",
        { page: allPage },
        (data) => {
          const isAlreadyAdded = data.data.find(
            (i) => i.id === generatedMessage.id
          );

          if (!isAlreadyAdded) {
            data.data.unshift(generatedMessage);
            data.pagination.total += 1;
          }
        }
      )
    );
  };

  async function requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });

      mutationRegisterFCMToken({ token: token });
      console.log("Token generated : ", token);
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  return <>{children}</>;
};
